@<template>
  <div class="box">
      <!-- 信息填入 -->
      <ul>
          <li>
              姓名
              <input type="text" placeholder="请输入姓名" v-model="userName">
          </li>
          
          <li @click="onClick(index)" v-for="(li,index) in list" :key="index">
              <em v-if="li.td !==''">{{li.td}}</em>
              <em v-else>请选择</em>
              {{li.th}}
              
          </li>
          
      </ul>
      <!-- 性别 -->
      <van-popup v-model="show1" position="top" style="width:375px" :style="{ height: '30%' }">
        <van-picker
            title="班级"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
            />
        </van-popup>
       
      <!-- 保存按钮 -->
      <div class="but">
          <button @click="baocun">保存</button>
      </div>
      <div class="but">
          <button @click="baocun">挑过</button>
      </div>
  </div>
</template>

<script>
import {Button} from 'vant'
import { Popup } from 'vant'
import { Picker } from 'vant';
export default {
    components: {
        [Button.name]:Button,
        [Popup.name]:Popup,
        [Picker.name]:Picker
    },
    data() {
        return {
            list:[{th:"性别",td:""},{th:"学校",td:""},{th:"班级",td:""},{th:"家长身份",td:""}], // 
            index:0, // list 下标
            show1:false, // 弹出框
            userName:"", // 用户名
            columns:[] , // 选项数组
            sex:['男','女'], 
            school:['']
        }
    },
    methods:{
        onClick(index){
            console.log(index);
            this.index = index // 下标赋值
            this.show1 = true  


        },
        onClick1(){
            //  this.$router.go(-1)
            this.show1 = true 
        },
    

         onConfirm(value, index) {
            // Toast(`当前值：${value}, 当前索引：${index}`);
            // console.log(value,index);
            // this.list.forEach((value,index,array)=>{
            //     array
            // })
            this.list[this.index].td = value
            console.log(this.list[this.index].td);
            console.log(value);
             this.show1 = false
        },
        onChange(picker, value, index) {
            console.log(picker,value,index);
            // Toast(`当前值：${value}, 当前索引：${index}`);
        },
        onCancel() {
            // Toast('取消');
            this.show = false
        },
        // 点击保存
        baocun(){
            console.log("点击保存");
            console.log(this.list);
            console.log(this.userName);
        }

        }
    }
/* export default {
    data() {
        return {
            
        }
    },
    methods:{
        onClick(){
            console.log(11);
        },
        baocun(){
             this.$router.push({
                          path: '/consulting',
                        })
        }
    }
} */
</script>

<style scoped>
    .box {
        font-size: .3rem;
        overflow: hidden;
    }
    img {
        width: 1.9rem;
        height: 1.9rem;
        background: red;
    }
    li {
        /* display: flex; */
        line-height: 49px;
        border-bottom: 1px solid red ;
        margin-left: 10px;
        padding-right:30px ;
        position: relative;
        font-size: .426667rem;
    }
    li::after {
        position: absolute;
        right: 10px;
        top: 50%;
        display: inline-block;
        content: "";
        width: 7px;
        height: 7px;
        border: solid #999;
        border-width: 1px 1px 0 0;
        -webkit-transform: translate(0, -50%) rotate(
            45deg
        );
        transform: translate(0, -50%) rotate(
            45deg
        );
    }
    input {
        width: 2.666667rem;
        float: right;
        position:absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        outline: 0;
        border: 0;
        font-size: 12px;
    }
    em {
        float: right;
        font-size: 12px;
    }
    .but {
        width: 80%;
        height: 30px;
        margin: .133333rem auto;

    }
    button {
        width: 100%;
        height: 100%;
    }
</style>