import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/video/Home.vue'
import Qrcode from '../views/saoma/Qrcode.vue' // 扫码
// import About from '../views/saoma/About' //  测试页
import Result from '../views/saoma/Result' // 
import Subject from '../views/saoma/Subject' // 
// 咨询引入组件
import HelloWorld from "../views/zixun/HelloWorld copy.vue"; // 列表页
import Information from "../views/zixun/information.vue"; // 专家详情页
import Consulting from "../views/zixun/consulting copy.vue"; // 咨询页
// 获取用户信息页面
import ParentType from "../views/user/parent-type"; //  选择家长类型页面
import AddChildren from "../views/user/add-children"; // 添加孩子信息页面
// h5引入小程序
import Subscribe from '../views/subscribe/Subscribe'
Vue.use(VueRouter)

const routes = [{
        path: '/ParentType',
        name: 'ParentType',
        component: ParentType,
        meta: { title: "选择家长身份" }
    }, {
        path: '/AddChildren',
        name: 'AddChildren',
        component: AddChildren,
        meta: { title: "添加孩子" }
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        meta: { title: "热门推荐" }
    },
    // 首页下拉视频
    {
        path: '/',
        name: 'VideoList',
        component: () =>import ('../views/video/VideoList'),
        meta: { title: "热门推荐" }
    },

    {
        path: "/popComment",
        name: 'popComment',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/index/PopComment'),
    },
    {
        path: "/hello",
        component: HelloWorld,
        meta: { title: "专家列表" }
    },
    {
        path: "/information",
        component: Information,

    }, {
        path: "/consulting",
        component: Consulting,
        meta: { title: "专家咨询"}
    }, {
        path: '/qrcode',
        name: 'qrcode',
        component: Qrcode,
        meta: { title: "扫码获取测评结果" }
    },
    /* {
        path: '/About',
        name: 'About',
        component: About,
        meta: { title: "扫码" }
    }, */
    {
        path: '/Subject',
        name: 'Subject',
        component: Subject,
        meta: { title: "您的选择项" }
    },
    {
        path: '/Result',
        name: 'Result',
        component: Result,
        meta: { title: "测评结果" }
    },
    // h5跳转外链
    {
        path: '/subscribe',
        name: 'Subscribe',
        component: Subscribe,
    }

]

const router = new VueRouter({
        routes
    })
    /* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.matched[0].meta.title
    }
    next()
})
export default router