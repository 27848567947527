<template>
  <div >
    <!-- 专家列表页面 -->
    <div class="list">
      <!-- 列表 -->
      <div  class="item" v-for="lis in list" :key="lis.id">
        <img :src="lis.lecturerHead" class="img">
        <div class="text">
          <div class="name" >{{lis.lecturerName}}</div>
          <div class="label" >{{lis.label[0]}} {{lis.label[1]}}</div>
        </div>
        <button class="but" @click="onDetails(lis.id)">我要咨询</button>
      </div>
      <div class="loading" style="display: none;">
          <span class="fa fa-spinner"></span>
      </div>
      <div></div>
       <div class="loaded">已全部加载</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// 知你聊天
/*  !function (e, t, n, a, i, r, c) {
                e[a] = e[a] || function () {
                    (e[a].a = e[a].a || []).push(arguments);
                };
                r = t.createElement(n);
                c = t.getElementsByTagName(n)[0];
                r.async = !0;
                r.charset = "UTF-8";
                r.src = "";
                c.parentNode.insertBefore(r, c);
            }(window, document, "script", "_ZHINI");
            _ZHINI({
                    channel_id: "60f12bce3dbf9978c6779330",   // 网页在线客服的渠道id，已自动填写;
                    client_id: "" ,   // 顾客的唯一标识，可为空;
                    // custom_config: ['withoutBtn']
             }); */

// var url1 = "http://192.168.0.176:" // 本地
var url1 = "http://aipycho.leading-ai.com:" // 网络
export default {
  data() {
    return {
      list:[],
      consultPhone:null, // 咨询人电话
      lecturerId:null, // 教师id
      consultId:null , // 对话id
      // 微信智能语言码
      signature:"",
      //  请求信息
      requestData:{
        method:"post",
        url:"/openapi/aibot/Z3tmllXw8C3nVfs6qGeY1NlbIoOO57",
        data:{
          userid:36233
        }
      }
    }
  },
  methods: { 
    
    // 点击咨询 
    setZixun(){
     
      // 获得不到手机号码 随机生成一个临时手机号
      /* if (!this.consultPhone) { 
        this.consultPhone = Math.ceil(Math.random()*1000)
      } */
      // 获得用户id ,跳转页面,调取第一次接口,获得signature给对话页面
      axios({
            method:'post',
            url :"/openapi/sign/Z3tmllXw8C3nVfs6qGeY1NlbIoOO57",
            data: {
              userid:this.consultPhone,
            }
      }).then(res=>{
        // return res.data.signature
        this.signature = res.data.signature
        // console.log(this.signature);
        // console.log(this.signature);
        
       /*  this.$router.push({
          path:"/consulting",
          query:{
            signature: this.signature
            // userid: this.consultPhone
          }
        }) */
        })
    },
    
    
    // 后台留言 第一版 
    onDetails(ID){
      // console.log(1111);
      // 获得不到手机号码 随机生成一个临时手机号
      if (!this.consultPhone) { 
        this.consultPhone = Math.ceil(Math.random()*1000)
      }
      
      // this.setZixun()
     
      // 1 判断手机号长度
      // 2 判断是否合理
      // 3 查询咨询人是否存在 请求 http://aipycho.leading-ai.com:9999/business/h5_consult/list  参数：电话号码 consultPhone
      //      3.1 有咨询人  查询对话内容 http://aipycho.leading-ai.com:9999/business/consultRecord/getRecordList  参数：对话id
      //
      //      3.2 没有咨询人 添加会话 http://aipycho.leading-ai.com:9999/business/h5_consult/add 参数： 电话号码 consultPhone， lecturerId
      //  4 发送信息 http://aipycho.leading-ai.com:9999/business/consultRecord/add  参数 consultId，contents，type=2
      // 假设手机号合理 、请求后台查看咨询人是否存在
      axios({
        method:'get',
        url: url1+'9999/business/h5_consult/list',
        params: {
            consultPhone: this.consultPhone,
            lecturerId:ID,
        }
        }).then((response)=>{
          // console.log(response.data);
            if (response.data.rows.length==0) {
              console.log("不存在");
              // 没有咨询人，添加会话请求
                axios({
                    method:'post',
                    url: url1+'9999/business/h5_consult/add',
                    data: {
                        consultPhone:this.consultPhone,
                        lecturerId: ID
                    }
                    }).then((response)=>{
                        console.log("对话id"+response.data.data);
                        this.$router.push({
                          path: '/consulting',
                          query: {
                            consultId: response.data.data,
                          
                          }
                        })
                    })
            }else{ 
              // 存在咨询人 获取聊天记录
                console.log("存在咨询人");
               console.log(response.data.rows[0].id);
               this.$router.push({
                    path: '/consulting',
                    query: {
                      consultId: response.data.rows[0].id,
                      
                    }
                  })
            }
      })
        },
    // 获取专家列表 与url上的电话号码
    getList(){
      axios({
            method:'get',
            url: url1+'9999/crmeb/getLecturerList',
            params: {
            }
        }).then((response)=>{
            this.list = response.data.data;
        })
        // 在当前url中获取 电话号码
        let url = location.href
        let index = url.indexOf("=") + 1
          if (index !== 0) { // 判断 = 号
              this.consultPhone =  url.substring(index, url.length)// 截取url 的电话号码、
          }
      }
  },
  mounted() {
     this.getList()
      // this.setZixun()


  },
}
</script>

<style scoped>
.but {
  background: rgb(42, 164, 226);
  color: #fff;
  border-radius: 25px;
  border: 0;
  font-size: .373333rem;
  width: 2.24rem;
  height: .933333rem;
  position: absolute;
  top: .88rem;
  right: .346667rem;
}
.lis {
  height: 100px;
  width: 100%;
  background: gold;
}
img {
  width: 1.92rem;
  height: 1.92rem;
  border-radius: 50% ;
  margin: .32rem .24rem ;
}
li {
  height: 30px;
}
 .item {
   height: 2.773333rem;
   width: 9.2rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    font-family: PingFangSC-Semibold;
    align-items: center;
    position: relative;
    margin: .4rem auto  ;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 1px 8px 40px 0px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    }

    .list {
      font-size: .08rem;
      width: 10rem;
height: 17.786667rem;
background: #FFFFFF

    }
    .text {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      min-width: 0;
      margin-left: .22rem;
    }
    .loaded {
    font-size: .28rem;
    line-height: .72rem;
    text-align: center;
    color: #999;
}
.item::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 1.49rem;
    /* border-bottom: 1px solid #f2f2f2; */
}
.list .item .name {
    font-weight: 600;
    overflow: hidden;
    font-family: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .426667rem;
    line-height: .586667rem;
    color: #2C384B;
    position: absolute;
    top: .426667rem;
    left: 2.56rem;

}
.label {
    position: absolute;
    left: 2.56rem;
    top: 1.226667rem;
    height: .426667rem;
    background: #FFF2E6;
    color: #EF7607;
    border-radius: .186667rem;
    padding: 0 5px;
    font-size: .266667rem ;
    line-height: .426667rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: .06rem;
}
 .label .cell {
    height: .34rem;
    padding: 0 .12rem;
    border-radius: .1rem;
    background-color: #FFF0E5;
    font-size: .22rem;
    line-height: .34rem;
    color: #FF6B00;
}
</style>
