<template>
<!--对话页面 -->
  <div class="hello">
        <div class="center">

             
            
            <!--自动回复记录-->
            <div class="chat-item is-left">
                <div class="msg">
                    <div class="avatar">
                        <img  class="img" src="https://yzf.qq.com/xv/web/static/img/def_ai.png" alt="">
                    </div>
                    <div class="content " >
                        <div class="bubble ">
                            <pre>您好，欢迎使用365家庭成长课堂，请问您需要咨询点什么?</pre>
                        </div>
                    </div>
                </div>
            </div>
            <!---->
            <div class="chat-list">
                <div v-for="(chat,index) in chartList" :key="index">
                    <!--右边-->
                    <div class="chat-item1 is-right1" v-if="chat.type==2">
                        <span style="display: flex;"> {{ chat.contents }}</span>
                    </div>
                    <!--左边-->
                    <div class="chat-item2 is-right2" v-if="chat.type==1">
                        <span style="display: flex;"> {{ chat.contents }}</span>
                    </div>
                    <!-- 图片测试 -->
                   <!--  <div class="chat-item2 is-right2" v-if="chat.type==1">
                        <span style="display: flex;"> <img style="width:30px; height:30px;" :src="upimg" alt="">{{ chat.contents }}</span>
                    </div> -->
                </div>
            </div>         
        </div>
        <div class="foot">
            <div class="input">
                <input  autofocus v-model="text" placeholder="请输入信息" >
                <button @click="onSubmit1">发送</button>
                <!-- <button @click="getRecordList">发送</button> -->
            </div>
        </div>

        <!-- 练习 -->
        
  </div>
</template>

<script>
import axios from 'axios'
// import Sonbotton from './test.vue'
// var url1 = "http://192.168.0.176:" // 本地
var url1 = "http://aipycho.leading-ai.com:" // 网络
export default {
    // components:{Sonbotton},
    props:[""],
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
      return {
        //   say:"",
        Adata:"",
        text:"",
        phone:"",
        list:[],
        lis:[],
        consultId:0, // 对话id
        chartList:[],
        upimg: window.sessionStorage.getItem('upimg'),
        // 
        signature:""
      }
  },
  methods: {
    //   测试
    add(){
        this.lis.push({})
        this.Adata = this.text
        alert("add调用成功")
    },


    //   获取对话列表// 获得 signature  发送信息时调用智能机器人接口
    getRecordList(){
        // this.signature =this.$route.query.signature
        /* axios({  
            method:"post",
            url:"/openapi/aibot/Z3tmllXw8C3nVfs6qGeY1NlbIoOO57",
            data:{
                signature:this.signature,
                query:"你是真人吗"
            }
        }).then(res=>{
            console.log(res.data);
        }) */

        // 如果会话id 为 20 ,创建临时会话
        if (this.$route.query.consultId == 20 || !this.$route.query.consultId) {
            this.$route.query.consultId = Math.ceil(Math.random()*1000);
            // console.log(this.$route.query.consultId); 
        }   
            //    3 查询对话内容 http://aipycho.leading-ai.com:9999/business/consultRecord/getRecordList  参数：对话id
            //  4 发送信息 http://aipycho.leading-ai.com:9999/business/consultRecord/add  参数 consultId，contents，type=2
        axios({
            method:'get',
            url:url1+'9999/business/consultRecord/getRecordList',
            params: {
                        consultId: this.$route.query.consultId,
                    }
        }).then((response)=>{
           this.list =  response.data.data
            // 翻转数组
            let newArr = []
            for(let i=this.list.length-1;i>=0;i--){
				newArr.push(this.list[i]);
			}
			// console.log(newArr);
            this.list =  newArr
            this.chartList = newArr
        
        })
    },
    onSubmit(){
        // 判断为空 显示出来 再请求成功时 刷新智能对话
    //   if(this.text !==""){
       /*  this.chartList.push({
                type: 2,
                contents: this.text
            }) */

        axios({
            method:'post',
            url:"/openapi/aibot/Z3tmllXw8C3nVfs6qGeY1NlbIoOO57",
            data:{
                signature:this.signature,
                query:this.text
            }
        }).then(res=>{
            this.text =""
            console.log(res.data.answer);
            // 请求接口
            
            axios({
            method:'post',
            url:url1+'9999/business/consultRecord/add',
            data: {
                consultId: this.$route.query.consultId,
                contents: res.data.answer,
                type: 1,
            }
        }).then((response)=>{
            console.log('请求成功'+res.data.answer)
            // father.appendChild(son);
            /* this.chartList.push({
                type: 2,
                contents: this.text
            })
            // this.text =""
            this.onSubmit() */
        })    

            this.text =""
            setTimeout(()=>{
                return this.chartList.push({
                    type: 1,
                    contents: res.data.answer 
            })},1500)
             
    
            
        })
    //  }
    },
    onSubmit1(){
        // console.log(this.signature);
        //   不为空时输出 并请求后台添加
        if(this.text !==""){
            
        axios({
            method:'post',
            url:url1+'9999/business/consultRecord/add',
            data: {
                consultId: this.$route.query.consultId,
                contents: this.text,
                type: 2,
            }
        }).then((response)=>{
            console.log('请求成功'+response)
            // father.appendChild(son);
            this.chartList.push({
                type: 2,
                contents: this.text
            })
            // this.text =""
            this.onSubmit()
        })
     }
    }
  },
  mounted() {
    //   查看聊天记录
    this.getRecordList()
    axios({
            method:'post',
            url :"/openapi/sign/Z3tmllXw8C3nVfs6qGeY1NlbIoOO57",
            data: {
              userid:this.$route.query.consultId, // 会话id 赋值
            }
      }).then(res=>{
        // return res.data.signature
        this.signature = res.data.signature
        console.log(this.signature);
      })
  },
  
}
</script>

<style  scoped>

/* 测试 */
#test {
    position: absolute;
    top: 0;
}

/* 用户发送消息 */
.chat-item1 {
    float: right;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    padding: 10px;
    background: #3C8BFA;
    border-radius: 5px;
    min-height:20px;
    font-family: inherit;
    color: #fff;
    font-size: .373333rem;
    line-height: .533333rem;
}
.chat-item1:before{
    position: absolute;
    border: 6px solid transparent;
    content: "";
    border-left-color: #3C8BFA;
    border-left-width: 8px;
    right: -13px;
    height: 0;
}
.is-right1 {
    clear: both;
    margin-bottom: 15px;
    position: relative;
}
/* 后台回复消息 */
.chat-item2 {
    float: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    min-height:20px;
    font-family: inherit;
    color: #000;
    box-shadow: 2px 4px 30px 0px rgba(0, 0, 0, 0.05);
    line-height: .533333rem;
}
.chat-item2:before{
    position: absolute;
    border: 8px solid transparent;
    content: "";
    border-right-color: #fff;
    border-right-width: 8px;
    left: -14px
}
.is-right2 {
    clear: both;
    margin-bottom: 15px;
    position: relative;
    font-size: .373333rem;
    color: #2C384B;
    line-height: .533333rem;
}



.hello {
    font-size: 0.3rem;
    font-family: inherit;
    background: #F9F9F9;
}

.head {
  width: 100%;
  height: 50px;
  background:  #3C8BFA;;
  position: fixed;
    top: 0px;
    line-height: 50px;
    text-align: center;
  /* display: ; */
  z-index:99;
  color:#fff;
  font-size: .426667rem
}
.center {
    padding: 15px .426667rem 1.6rem;
    /* width: 100%; */
    min-height: 92vh;
    overflow: hidden;
}
.chat-item {
    width: 100%;
    padding-bottom: 15px;
    /* background: pink; */
    overflow: hidden;
}
.msg {
    position: relative;
}
.chat-item.is-right .msg {
    text-align: right;
}
.avatar {
    position: absolute;
}
.img {
    border-style: none;
    vertical-align: middle;
    height: 1.066667rem;
    width: 1.066667rem;
    border-radius: 50%;
}

.content {
    padding-right: 20.5px;
     padding-left: 1.066667rem
}
pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    min-height:20px;
    font-family: inherit;
    float: left;;
    font-size: .373333rem;
    color: #2C384B;
    line-height: .533333rem;
        /* max-width: 80%; */
}
.bubble {
    margin-left:10px ;
    position: relative;
}
.is-left .bubble:before {
    position: absolute;
    top: 10px;
    border: 6px solid transparent;
    content: " ";
    border-right-color: #fff;
    border-right-width: 8px;
    left: -14px;
} 
/* 发送 右边 */
.is-right .bubble:before {
    position: absolute;
    top: 10px;
    border: 6px solid transparent;
    content: " ";
    border-left-color: #4979ff;
    border-left-width: 8px;
    right: -14px;
}
.is-right pre {
    float: right;
    background: #4979ff;
    color: #fff;
}
.foot {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  height: 1.6rem;
  bottom: 0;
  /* margin-right: .426667rem; */
}
input {
    width: 70%;
    font-size: .373333rem;
    height: 40px;
    margin: 5px;
    outline: none;
    border-radius: 10px;
    border: 0;
    width: 6.133333rem;
    height: .933333rem;
    background: #F9F9F9;
    padding-left:.426667rem;
    border-radius: 18px;
    margin: .346667rem  0 0 .426667rem;
    box-sizing: border-box;
    }
button {
    width: 2.853333rem;
    height: .933333rem;
    background: #3C8BFA;
    border-radius: .773333rem;
    border: 0;
    color: #FFFFFF;
    margin-left: .426667rem;
    font-size: .373333rem;
}
.chat-item .tips {
    font-size: 12px;
    background: #F9F9F9;
    text-align: center;
    padding: 10px 0 15px;
}
</style>
