<template>
    <div class="header">
        <input type="text" v-model="val" placeholder="搜索"/>
        <span class="iconfont icon-sousuo"></span>
    
        <div class="text-search">搜索</div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      val: "",
     
    };
  },
  created(){
    console.log(this.$route.query)
  }
};
</script>

<style scoped>
.header {
  width: 100vw;
  height: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  font-size: 0.4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0rem 5px;
  box-sizing: border-box;
  background: #ffffff;
}
.header input {
  width: 90%;
  height: 35px;
  outline: none;
  padding: 0rem 0.8rem;
  box-sizing: border-box;
  position: relative;
}
.header  .iconfont {
  position: absolute;
  left: 15px;
  top: 7px;
}
.header .text-search {
  flex: 1;
  text-align: center;
}
</style>
