<template>
  <div class="box">
    <!-- <header-tab></header-tab> -->
    <div class="waterfall">
      <vue-waterfall-easy
        ref="waterfall"
        :imgsArr="imgsArr"
        @scrollReachBottom="getData"
        @click="clickFn"
        class="video-player vjs-custom-skin"
      >
        <div class="info" slot-scope="props">{{ props.value.title}}
        </div>
       
      </vue-waterfall-easy>
    </div>
  </div>
</template>

<script>
 import vueWaterfallEasy from "vue-waterfall-easy";
 import HeaderTab from '@/components/video/HeaderTab.vue'
export default {
  components: {
    vueWaterfallEasy,
    HeaderTab
  },
  data() {
    return {
      imgsArr: [],
      page: 0,
      num:10,
      userInfo:{}
    };
  },
  mounted() {
    this.getAxios()
  //  console.log(Math.floor(Math.random()*400))
  },
  created(){
    // 将路由上传的信息保存
     this.userInfo={
           avatar:'http://e-learning.leading-ai.com'+this.$route.query.avatar,
           nickname:this.$route.query.nickname,
           phone:this.$route.query.phone
      }
      // 存用户信息缓存
      localStorage.setItem('h5_userInfo',JSON.stringify(this.userInfo))
  },
  methods: {
    // 接口请求
    getAxios(page=Math.floor(Math.random()*400),num=10){
      // console.log(this.num)
      this.num=num
      this.$axios('http://aipycho.leading-ai.com:9999/crmeb/getVideoList',{params: {
        pageNo:page,
        pageSize:num
      }}).then((result) => {
        let kdoeimg =result.data.data
        this.imgsArr = this.imgsArr.concat(kdoeimg);
      }).catch((err) => {
        
      });

    },
    // 上拉刷新
    getData() {
        // this.$refs.waterfall.waterfallOver();
        this.num+=5
        this.getAxios(10*this.num,this.num)
      
      //  console.log(this.imgsArr)
     
    },
 
    //  点击跳转视频
   clickFn(event, { index, value }) {
    // 阻止a标签跳转
    event.preventDefault()
    // 只有当点击到图片时才进行操作
    if (event.target.tagName.toLowerCase() == 'img') {
      // console.log('img clicked',index, value)
      // console.log(value.link)
      // this.$router.push({name:'Live',params: {id:value.link,path:value.src}})
      this.$router.push({name:'VideoList',params: {id:value}})
    }
   
  },
 

  },
};
</script>

<style  scoped>
.waterfall {
  position: absolute;
  /* top: 1.173333rem; */
  left: 0;
  width: 100%;
  height:100%;
  overflow: hidden;
}


.info {
  text-align: center;
 font-size: 14px;
    
}
</style>