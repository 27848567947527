<template>
	<div id="result">
		<div class="header">
			<div class='go'>
				<!-- <img src="../assets/result/fanhui.png"/> -->
				<div class="go-title" v-if="result.scaleName">{{result.scaleName}}</div>
				<div class="go-title" v-else>暂无数据</div>
			</div>
		</div>
		<div class='content'>
			<div class="header-footer"></div>
			<div class="yuan-title"	v-if="result.totalScore">{{result.totalScore}}</div>
			<div class="yuan-title" v-else>0</div>
			<div class="yuan-img"></div>
			<div class="ceping-title">本次测评得分</div>
			<ul class="fenshu">
				<!-- 效果一分数区间 -->
				<!-- <li class="item" v-for="(sectionLi,index) in sectionList" :key="index">
					<div>{{sectionLi.result}}</div>
					<div>{{sectionLi.maxScore}}</div>
				</li>  -->
				<!-- 效果二测评结果 -->
				<li class="item">
					<div>{{result.totalResult}}</div>
					<div v-for="(sectionLi,index) in sectionList" :key="index" >
		 				<div v-if="result.totalResult==sectionLi.result">{{sectionLi.minScore}}~{{sectionLi.maxScore}}</div>
						<!-- <div v-if="result.totalResult==sectionLi.result">{{sectionLi.minScore}}~{{sectionLi.maxScore}}</div> -->
					</div>
				</li>

				<!-- <li class="item">
					<div>70</div>
					<div>焦虑</div>
				</li>
				<li class="item">
					<div>50</div>
					<div>中度焦虑</div>
				</li>
				<li class="item">
					<div>30</div>
					<div>焦虑</div>
				</li>
				<li class="item">
					<div>70</div>
					<div>焦虑</div>
				</li> -->
				
			</ul>
			<div class="context">
				<div class="context-title">测评建议</div>
				<div class="asdie" v-if="result.suggest !=='undefined'  ">
					{{result.suggest}}	
				</div>
				<div class="asdie" v-else >
					暂无建议	
				</div>
			</div>

		</div>
		<button class="btn" @click="daticlick">查看答题情况</button>
	</div>
</template>

<script>
	// import Hader from "@/comment/components/Header.vue"
	export default {
		data() {
			return {
				 result:{}, // 总数据
				 sectionList:[], // 区间 list 
				 question:null,
				 
			}
		},
		created() {
			
		},
		mounted() {
			this.getdata()
			
			console.log(window.sessionStorage.getItem("testId") );
		},
		methods: {
		   daticlick(){
			   console.log(this.testId);
				if (!this.result.question) {
					alert("暂无答题情况")
					return
				}
			//    通过浏览器缓存使用
			// window.sessionStorage.setItem("question",this.result.question)
			//    通过路由传数据()
			   this.$router.push({
				   path:'/subject',
				   query:{
					   question :  this.result.question, // 含有每一条的答题结果
					   questionOption : this.result.questionOption // 含有每一题的选项
				   }})

		   },
		   getdata(){
			   let url = "http://aipycho.leading-ai.com:9999/"
			//    let url = "http://192.168.0.230:9999/"
			   this.$axios({ 
				   metoud:'get',
				   url:url+"business/result/selectQuestion",
				   params: {
					//    testId: this.$route.query.testId
					   testId: window.sessionStorage.getItem("testId")
					//    testId: 162571384900013 
				   }
			   }).then((response)=>{
				//    console.log(response.data.data);
				   if (!response.data.data) { // 后台传出空对象 ,不执行下一步
					   alert("暂无数据，请重试")
					   return
				   }
				   this.result = response.data.data  // 全部数据
				   this.sectionList = response.data.data.scaleTotalStandard // 测试区间
				//    console.log(response.data.data);
				//    console.log("请求成功");
					// alert("请求成功")
			   })
		   }

		

		},
		components: {
			// Hader
		}
	}
</script>

<style  scoped>

    *{
		padding: 0;
		margin: 0;
 	}
	 .header {
		width:100%;
		height:4.24rem;
		background: url(../../assets/result/h5_background.png) no-repeat center;
		background-size: 100% 100%;

	}

	.header-footer {
		height: 2.213333rem;
		background: url(../../assets/result/h5_心形.png) no-repeat;
		background-size: 100% 100%;
	}

	.go {
		height:1rem;
		display: flex;
		align-items: center;
		padding-left: .25rem;
		padding-top: .25rem;
	}

	.go image {
		width:4.266667rem;
		height:4.293333rem;
	}

	.go-title {
		/* width:3.333333rem; */
		/* text-align: center; */
		position: absolute;
		font-size: .426667rem;
		color: #2C384BFF;
		font-weight: bold;
		left: 50%;
		transform: translateX(-50%);
		/* margin-left: -1.5625rem; */
		/* overflow: hidden; */
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.content {
		background-color: #FBFEFF;
		border-radius: .64rem .64rem 0 0;
		margin-top:-0.266667rem;
		z-index: 999;
		position: relative;
	}

	.yuan-img {
		width: 4.266667rem;
		height:4.293333rem;
		background: url(../../assets/result/h5_圆环.png) center center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 1%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: yaun 2s linear;
	}

	@keyframes yaun {
		from {
			transform: translate(-50%, -50%) rotate(0deg);
		}

		to {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}

	.yuan-title {
		font-size: 1.12rem;
		color: #00BA61FF;
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
		text-align: center;
	}

	.ceping-title {
		font-size: .373333rem;
		color: #2C384BFF;
		font-weight: bold;
		text-align: center;
		margin-top: .666667rem;
	
	}

	.fenshu {
		width: 9.2rem;
		height: 2.133333rem;
		background-color: #F6F7F9FF;
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: .32rem auto;
		border-radius: .213333rem;
	}

	.item {
		flex: 1;
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		font-size: .48rem;
		font-weight: bold;
		color: #2C384BFF;
	}

	.item>div:last-child {
		font-size: .373333rem;
		color: #2C384BFF;
		font-weight: normal;
	}

	.context {
		width: 9.2rem;
		margin: 0 auto;
		padding-bottom: 1.6rem;
        border-radius: .213333rem;
	}

	.context-title {
		font-size: .426667rem;
		color: #2C384BFF;
		font-weight: bold;
		padding-top: .266667rem;
		padding-left: .266667rem;
		background-color: #F6F7F9FF;
		
	}

	.asdie {
		font-size: .373333rem;
		color: #999999;
		font-weight: 400;
		font-size: .4rem;
		background-color: #F6F7F9FF;
		padding: .266667rem;
	}

	.btn {
		width:4.453333rem;
		height: .96rem;
		background-color: #2AA4E2;
		color: #FFFFFF;
		font-size: .373333rem;
		margin: 0 auto;
		border-radius: .56rem;
		border: none;
		position: fixed;
		bottom: 0%;
		z-index: 999;
		left: 50%;
		transform: translate(-50%, -50%);
	} 
</style>
