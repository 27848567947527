<template>
	<div class="page">
		<div class="header">
			<image src="@/assets/result/fanhui.png"  class="header-img"></image>
		</div>
		
		<div class='content'>
			<div class='shuzi'>
				<div class="shuzi-first">{{number}}</div>
				<div class="shuzi-last">/{{question.length}}</div>
			</div>
			<div class="title">{{question[number-1].questioContent}}</div>
			<ul class="timu">
				<!-- 选项 -->
				<li v-for="(lis,index) in newList" :key="index">
				<!-- <div class="roundUser"></div> -->
				<div class="roundUser" v-if="lis.optionContent == resultList[number-1].answer"></div>
				<div class="round" v-else></div>
				<span>{{lis.optionContent}}</span>
				</li>

				<!-- <li>
				<div class="round"></div>
				<span>同意</span>
				</li>
				<li>
				<div class="round"></div>
				<span>不同意</span>
				</li>
				<li>
				<div class="round"></div>
				<span>非常同意</span>
				<li>
				<div class="round"></div>
					<span>比较同意</span>
				</li> -->
			</ul>
			<div class="btnClass">
				<button class="left-btn" style="left:.426667rem;background-color: #2AA4E2;color:#fff;border:0" @click="thePrevious" v-if="number>1">上一题</button>
				<button class="left-btn" style="left:.426667rem;" @click="thePrevious" v-else>上一题</button>
				<button class="right-btn" style="right:.426667rem" @click="theNext" v-if="question.length>number">下一题</button>
				<button class="right-btn" style="right:.426667rem" @click="theNext" v-else>关闭</button>
				<!-- <button class="right-btn1" style="right:.426667rem" @click="theNext" v-else>下一题</button> -->
				<!-- <button class="right-btn" style="right:.426667rem" @click="theNext">下一题</button> -->
			</div>
			
			
		</div>
		<div class="test"></div>
		
	</div>
</template>

<script>
export default {
		data() {
			return {
				number:1,// 当前数据列表第几页
				question:[], // 含有每一条的答题结果
				questionOption:[], // 含有每一题的选项
				list : [], //  list[0] ===  newList
				newList : [] , // 
				resultList : [], // 用户选项结果
			}
		},
		methods: {
			// 上一页
			thePrevious(){
				// 点击减一
				if (this.number >1) {
					this.number -=1
					this.newList = this.list[this.number-1].optionContent  
				}	
			},
			// 下一页
			theNext(){
				// 点击加1
				if ( this.question.length  > this.number >= 1 ) {
					this.number +=1
					// console.log(this.list);
					this.newList = this.list[this.number-1].optionContent  
					// console.log(this.number);
					// console.log(this.newList);
				}else{
					// alert("已经到底了")
					// this.$router.push({
				   	// 	path:'/result'})
						   this.$router.go(-1)
				}
				
				
			}
		},
		mounted() {
			/* console.log(this.$route.query.question);
			this.question = this.$route.query.question
			this.questionOption = this.$route.query.questionOption
			console.log(this.question); */

		},
		created() {
			// console.log(this.$route.query.question);
			this.question = this.$route.query.question  //  含有用户选项结果
			this.questionOption = this.$route.query.questionOption // 含有每一条的 选项 
			this.list = this.$route.query.questionOption // 
			this.newList = this.$route.query.questionOption[0].optionContent
			this.resultList = this.$route.query.question
			// console.log(this.list);
 			// console.log(this.newList); 
			//  console.log(this.resultList);
 			// console.log(this.question);
			
		},
	}

</script>

<style scoped>
	body{
		background-color: #E3EFF5;
		padding: 0;
		margin: 0;
	}
	.page {
		height: 100vh;
		
	}
	.test {
		position: absolute;
		height: 100vh;
		width: 100vw;
		top: 0;
		background: #E3EFF5;
		z-index: -999;

	}
	.header {
		height: 4.24rem;
		background: url(../../assets/result/h5_background.png) center center no-repeat;
		background-size: 100% 100%;
	}
      .header-img{
		   border: 1px solid red;
	  }
	.content {
		width: 9.2rem;
		background-color: #FFFFFF;
		border-radius: .64rem;
		/* margin: 0 auto; */
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -2.32rem;
		padding: .32rem;
		box-sizing: border-box;
		min-height: 14.96rem;
	}
	
	.shuzi {
		height: 1.333333rem;
		display: flex;
		line-height: 1.333333rem;
	}

	.shuzi-first {
		font-size: .853333rem;
		font-weight: 600;
		color: #2C384B;
		padding-right: .133333rem;
	}

	.shuzi-last {
		font-size: .4rem;
		font-weight: 400;
		color: #999999;
	}

	.title {
		font-size: .48rem;
		color: #2C384B;
		line-height: .666667rem;
		font-weight: bold;
		
	}
	*{
    padding: 0;
    margin: 0;
}
	.timu {
		margin-top: .666667rem;
		padding: 0;
		height: 8rem;
		overflow-y: scroll;
	}
.timu li{
	list-style: none;
	margin-bottom: .8rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}
/*  */
.timu li .round{
	 width: .453333rem;
	 height: .453333rem;
	 border: .053333rem solid #999;
	 border-radius: 50%;

}
/* 用户的选项 */
.timu li .roundUser{
	 width: .453333rem;
	 height: .453333rem;
	 border: .053333rem solid #FFAD5D;
	 background: #FFAD5D;;
	 border-radius: 50%;

}
.timu li span{
	 display: block;
	 width: 7.266667rem;
	 font-size: .4rem;
	 color: #2C384B;
	 margin-left: .266667rem;
}
  .btnClass{
	  height: 1.333333rem;
	  display: flex;
	  justify-content:space-between;
	  /* margin-top: 4.666667rem; */
  }
  .left-btn,.right-btn{
	  width: 3.386667rem;
	  height: 1.066667rem;
	  line-height: 80rpx;
	  border: .026667rem solid #999;
	  border-radius:.8rem;
	  color: #999;
	  text-align: center;
	  font-size: .373333rem;
	  position: absolute;
	  bottom: 1.253333rem;
  }
  .right-btn{
	  background-color: #2AA4E2;
	  color: #ffffff;
	  border: none;
  }
  .right-btn1{
	  background-color: #5e5e5e;
	  color: #ffffff;
	  border: none;
  }
</style>
