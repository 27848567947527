<template>
  <!-- 这是专家信息页面 -->
  <div>
      <!-- 信息一览 -->
      <div class="heads">
          <img src="" alt="">
      </div>
      <!-- 个人简介 -->
      <div class="nav">
           个人简介
      </div>
      <div style="font-size: 0.3rem;">
          <!-- {{list[0].jobTitle}} <br> -->

      </div>
      <!-- 立即咨询按钮 -->
        <button class="foot" @click="onPhone">立即咨询</button>
        <!-- 提示输入手机号 -->
        <div class="entry up" v-if="flag">
            <h5 style="font-size: 0.5rem;text-align: center;line-height: 2;">请输入您的手机号</h5>
            <input type="text" v-model="phone" placeholder="请输入11位手机号" >
            <button @click="onConsulting">确定</button>
           

        </div>
         <div class="zhezao">
                
        </div>
        <div class="zhezao1">
            <div style="flex:1;line-height: 2.5;    text-align: center;    font-size: 24px;    font-weight: 600;color:#2C384B ">
                请输入手机号
            </div>
            <div  style="flex:1">
                <img src="@/assets/phone.png" style="    top: 50%; position: absolute;  margin-top: -20px;  left: 50%;margin-left: -110px;z-index: 999;" alt="">
                <input type="text" v-model="phone" style="border-radius: 23px;    border: 1px solid #ccc;width: 250px;height:50px;outline: none;padding-left: 35px;box-sizing: border-box; position: absolute;left: 50%;margin-left: -125px;position: absolute;}">
            </div>
            <div style="flex:1;">
                <button style=" background: rgb(42, 164, 226);border:0;    color: #Fff;    color: #Fff;height: 50px;border-radius: 28px;font-size: 18pxwidth: 200px; position: absolute;left: 50%;margin-left: -100px;width: 107px;height:35px;marg
                postion:absolute;left:50%;margin-left:-53px" @click="onConsulting">确定</button>
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            list: "",
            flag:false,
            // 电话号码
            phone :'',
            userData: {
                consultPhone:null,
                specialistId:null,
            },
            

        }
    },
    methods: {
        // 点击咨询弹出用户输入电话
        onPhone(){
            this.flag = true;
            
        },
         checkPhone(){ 
            
            if(!(/^1[3456789]d{9}$/.test(phone))){ 
                alert("手机号码有误，请重填");  
                return false; 
            } 
        },
        // 跳转到咨询对话组件
        onConsulting(){
            // 1 判断手机号长度
            // 2 判断是否合理
            // 3 查询咨询人是否存在 请求 http://aipycho.leading-ai.com:9999/business/h5_consult/list  参数：电话号码 consultPhone 
            //      3.1 有咨询人  查询对话内容 http://aipycho.leading-ai.com:9999/business/consultRecord/getRecordList  参数：对话id
            //                    
            //      3.2 没有咨询人 添加会话 http://aipycho.leading-ai.com:9999/business/h5_consult/add 参数： 电话号码 consultPhone， lecturerId
            //  4 发送信息 http://aipycho.leading-ai.com:9999/business/consultRecord/add  参数 consultId，contents，type=2
            var pattern = new RegExp("^1[34578][0-9]{9}$", 'i');
            if (this.phone!=='') {
                // 请求后台
                 axios({
                    method:'get',
                    url:'http://aipycho.leading-ai.com:9999/business/consultRecord/addConsultUser',
                    params: {
                        phone:Number(this.phone)
                    }
                    }).then((response)=>{
                    console.log('成功请求'+response)
                    // this.list =   response.data.data;
                    })
                console.log(this.phone);
                window.phone = this.phone
            //    console.log(window.phone);
               this.$router.push('/consulting') 
               
            }
            
        },
        // 获得当前专家详情
        // getDetails(){
        //      axios({
        //     method:'get',
        //     url:'http://aipycho.leading-ai.com:9999/business/specialistInfo/getSpeciaInfo',
        //     params: {
                
        //     }
        //     }).then((response)=>{
        //     console.log('请求教师列表成功')
        //     this.list =   response.data.data;
            
        //     })
        // }
    },
    mounted() {
        console.log(2);
        // this.getDetails()
    },
}
</script>

<style scoped>
/* 提示用户输入手机 */
.entry {
    width: 100%;
    height: 20%;
    position: fixed;
    bottom: 0;
    background: pink;
}
.heads {
    height: 180px;
    width: 100%;
    background: gold;
}
.nav {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    border-radius: .3rem .3rem 0 0;
    margin-top: -.33rem;
    background-color: #fff;
    box-shadow: 0 0.06rem 0.2rem rgb(0 0 0 / 3%);
    overflow: hidden;
        font-weight: bold;
    font-size: .32rem;
    color: #2c8eff;
    height: 50px;
    line-height: 50px;
}
.foot {
  width: 100%;
  height: 50px;
  background: rgb(42, 164, 226);
  position: fixed;
    bottom: 0px;
    border:0;
    color: #fff;
    font-size: 14px;
}
button {
    width: 100%;
    height: 30px;
}
.zhezao {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgb(79 79 79 )
}.zhezao1 {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 250px;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    border-radius: 15px;
}
</style>