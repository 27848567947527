@<template>
  <div class="box">
      <div class="minbox">
          <div style="flex:1" @click="on()"><img src="" alt="">爸爸</div>
          <div style="flex:1"><img src="" alt="">妈妈</div>
        </div>
        <div class="minbox">
          <div style="flex:1"><img src="" alt="">爷爷</div>
          <div style="flex:1"><img src="" alt="">奶奶</div>
        </div>
        <div class="minbox">
          <div style="flex:1"><img src="" alt="">外公</div>
          <div style="flex:1"><img src="" alt="">外婆</div>
        </div>
        <div class="minbox">
          <div style="flex:1"><img src="" alt="">其他</div>
          <div style="flex:1"></div>
        </div>
        <van-button></van-button>
        <!-- <Button/> -->
        <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
        <van-popup v-model="show" position="bottom  " :style="{ height: '30%' }">
        <van-picker
            title="班级"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
            />
        </van-popup>
  </div>
</template>

<script>
import {Button} from 'vant'
import { Popup } from 'vant'
import { Picker } from 'vant';
export default {
    components: {
        [Button.name]:Button,
        [Popup.name]:Popup,
        [Picker.name]:Picker
    },
    data() {
        return {
            list:[],
            show:false,
            flag:false,
            columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
        }
    },
    methods:{
        on(){
            //  this.$router.go(-1)
            this.show = true
            this.flag = true
        },

         onConfirm(value, index) {
            // Toast(`当前值：${value}, 当前索引：${index}`);
            console.log(value,index);

        },
        onChange(picker, value, index) {
            console.log(picker,value,index);
            // Toast(`当前值：${value}, 当前索引：${index}`);
        },
        onCancel() {
            // Toast('取消');
            this.show = false
        },

        }
    }
</script>

<style scoped>
    .box {
        display: flex;
        flex-direction: column;
    }
    .minbox {
        display: flex;
        height: 2.773333rem;
        width: 9.2rem;
        -webkit-box-align: center;
        -webkit-align-items: center;
        font-family: PingFangSC-Semibold;
        align-items: center;
        position: relative;
        margin: .4rem auto;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 1px 8px 40px 0px rgb(0 0 0 / 3%);
        border-radius: 15px;
    }
    img {
        width: 1.9rem;
        height: 1.9rem;
        flex: 1;
    }
</style>