import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'normalize.css/normalize.css' //引入默认样式
// import VideoPlayer from 'vue-video-player'
// import  from ''
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import './js/flexible'
// Vue.use(VideoPlayer)

// 知你咨询 配置
/* import './views/zixun/zhini'
import './views/zixun/zhini2'
axios.defaults.baseURL = 'http://192.168.0.203:9999/' */

//引入阿里图标库
import './assets/iconfont/iconfont.css'
import './assets/iconfontKode.css'
//导入组件+样式
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

//播放器
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
//使用
Vue.use(VueAwesomeSwiper)
Vue.use(VueVideoPlayer)

// h5跳转小程序
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.config.productionTip = false
Vue.prototype.$axios = axios


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')