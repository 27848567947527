<template>
  <div class="scanCode_info">
    <div class="score_header" v-if="ticketNum !== ''">
      <div class="return__icon" @click="returnBack">
        <i class="iconfont icon_lulufanhui"></i>
      </div>
      <div class="title">扫码结果</div>
    </div>
    <div class="scanCode_second">{{ticketNum}}</div>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
export default {
  data () {
    return {
      ticketNum: '',
      configContent: []
    }
  },
  mounted () { // 刚进来页面就检查wx配置
    this.getSign()
      console.log(window.location.href.split('#')[0])
  },
  methods: {
    returnBack () {
      this.$router.push('/home')
    },
    getSign () {
      const divI = document.getElementsByClassName('scanCode_info')[0] // 后续修改的样式，可以不用
      divI.style.backgroundColor = '#9c9c9c'
      // this.$vux.loading.show({
      //   text: '加载中...'
      // })
      let url = window.location.href.split('#')[0]
      // alert('url：' + url)
      this.$axios.get('http://aipycho.leading-ai.com:9999/forward/weiXin?url='+url).then(res => { // url为例子，根据自己的项目来
        if (res.data.code === 200) {
          this.configContent = res.data.data  // 接口返回的数据，用于下面的配置
          // console.log(res.data.data)
          // console.log(this.configContent[0].appId)
          this.signWX()
        }
      })
    },
    signWX () {
      let _this = this
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.configContent[0].appId, // 必填，公众号的唯一标识
        timestamp: this.configContent[0].timestamp, // 必填，生成签名的时间戳
        nonceStr: this.configContent[0].NonceStr, // 必填，生成签名的随机串
        signature: this.configContent[0].signature, // 必填，签名
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
      })
      wx.ready(function () {
        const divI = document.getElementsByClassName('scanCode_info')[0]
        divI.style.backgroundColor = ''
        // _this.$vux.loading.hide()
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            _this.ticketNum = res.resultStr // 当needResult 为 1 时，扫码返回的结果
            window.sessionStorage.setItem("testId",_this.ticketNum)
            _this.$router.push({
                    path: '/result',
                    query: {
                      // consultId: response.data.rows[0].id
                      testId: _this.ticketNum  // testID
                    }
                  })
            
            console.log(_this.ticketNum);
          }
        })
        // alert('成功')
        // wx.checkJsApi({
        //   jsApiList: ['scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        //   success: function (res) {
        //     // 以键值对的形式返回，可用的api值true，不可用为false
        //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        //     console.log('成功：', res)
        //     alert('成功' + res)
        //   }
        // })
      })
      wx.error(function (res) {
        const divI = document.getElementsByClassName('scanCode_info')[0]
        divI.style.backgroundColor = ''
        // _this.$vux.loading.hide()
        console.log('微信js-sdk 配置失败000' + res.errMsg)
        alert('微信js-sdk 配置失败000' + res.errMsg)
      })
    },

  }
}
</script>
<style  >
  .scanCode_info {
    height: 100%;
    position: relative;
  }
  .score_header {
    font-size: 16px;
    width: 100%;
    background-color: #42b983;
    color: #fff;
    line-height: 40px;
    flex: none;
    z-index: 1;
  }
  .return__icon{
    margin-left: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
  }
  .iconfont {
    margin-top: 10px;
    font-size: 20px;
  }
  .title1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .scanCode_second {
    margin-top: 18%;
    text-align: center;
    color: #3c3c3c;
  }
</style>