<template>
     <div class="home">
      <wx-open-launch-weapp
        id="launch-btn"
        :username="username"
        :path="path"
        @launch="handleLaunchFn"
        @error="handleErrorFn"
      >
        <script type="text/wxtag-template">
          <style>
              .ant-btn {
                  line-height: 1.499;
                  position: relative;
                  display: inline-block;
                  font-weight: 400;
                  white-space: nowrap;
                  text-align: center;
                  background-image: none;
                  border: 1px solid #d9d9d9;
                  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,0.015);
                  box-shadow: 0 2px 0 rgba(0,0,0,0.015);
                  cursor: pointer;
                  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                  transition: all .3s cubic-bezier(.645, .045, .355, 1);
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  -ms-touch-action: manipulation;
                  touch-action: manipulation;
                  height: 32px;
                  padding: 0 15px;
                  font-size: 14px;
                  border-radius: 4px;
                  color: rgba(0,0,0,0.65);
                  background-color: #fff;
              }
              .ant-btn-red {
                  color: #fff;
                  background-color: #FF5A44;
                  border-color: #FF5A44;
                  text-shadow: 0 -1px 0 rgba(0,0,0,0.12);
                  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,0.045);
                  box-shadow: 0 2px 0 rgba(0,0,0,0.045);
              }
          </style>
          <button class="ant-btn ant-btn-red">{{ btnText }}</button>
        </script>
      </wx-open-launch-weapp>
    </div>

</template>
<script>
import wx from "weixin-js-sdk";
import { wxConfig } from "../../http/video/subscribe";
export default {
  data() {
     return {
      username:'gh_6bbf264b3572', // gh_ 开头的原始小程序ID
      path: 'pages/index/index.html', // 一定要以 .html 结尾
      btnText: '我的小程序',
    };
  },
  methods: {
    getShopWxConfig() {
      let that = this;
      let url = window.location.href;
      //getWechatConfig为请求后台接口
      wxConfig(url).then(res => {
         this.configContent = res.data.data  // 接口返回的数据，用于下面的配置
        wx.config({
          debug: true,
        appId: this.configContent[0].appId, // 必填，公众号的唯一标识
        timestamp: this.configContent[0].timestamp, // 必填，生成签名的时间戳
        nonceStr: this.configContent[0].NonceStr, // 必填，生成签名的随机串
        signature: this.configContent[0].signature, // 必填，签名
          openTagList: ['wx-open-launch-weapp'],
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'checkJsApi',
            'scanQRCode',
          ], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          console.log('res44444444');
        });
        wx.error(function (err) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log('err44444444', err);
        });
        
      });
    },
     handleLaunchFn(e) {
      console.log('success', e);
     },
    handleErrorFn(e) {
      console.log('fail', e.detail);
    },

  },
  async mounted() {
    // this.appSource()
    await this.getShopWxConfig();
  },
};
</script>

<style scoped>
</style>
